import React from 'react';

const Search = () => {
    return (
        <svg className="search" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.983 14.0895H15.9705L21.2705 19.4145C21.783 19.927 21.783 20.7645 21.2705 21.277C20.758 21.7895 19.9205 21.7895 19.408 21.277L14.0955 15.9645V14.977L13.758 14.627C12.008 16.127 9.62051 16.902 7.08301 16.477C3.60801 15.8895 0.833009 12.9895 0.408009 9.48946C-0.241991 4.20196 4.20801 -0.248042 9.49551 0.401958C12.9955 0.826958 15.8955 3.60196 16.483 7.07696C16.908 9.61446 16.133 12.002 14.633 13.752L14.983 14.0895ZM2.8456 8.46445C2.8456 11.577 5.3581 14.0895 8.4706 14.0895C11.5831 14.0895 14.0956 11.577 14.0956 8.46445C14.0956 5.35195 11.5831 2.83945 8.4706 2.83945C5.3581 2.83945 2.8456 5.35195 2.8456 8.46445Z" fill="#009FE3" />
        </svg>
    );
}

export default Search;