import React from 'react'

const LogoUs = () => {
    return (
        <svg width="234" height="78" viewBox="0 0 234 78" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M141.551 32.3747L138.276 33.598C141.465 5.13755 113.801 0.815484 113.456 0.733936L108.888 0L113.025 2.28336L110.009 3.50658C95.9612 9.70426 92.5139 20.0609 92.1692 28.0527C92.1692 28.0527 91.9107 37.6754 96.0473 44.1177L96.6506 40.5296C102.339 44.9332 109.75 47.135 115.61 46.9719C122.246 46.8904 127.59 44.8516 131.554 42.4867C131.468 42.6498 138.362 38.8171 141.551 32.3747Z" fill="white" />
            <path d="M118.627 8.80714C118.627 8.80714 92.9446 12.3137 95.4439 41.5081C95.4439 41.5081 85.964 15.7388 110.612 4.97437L118.627 8.80714Z" fill="#005286" />
            <path d="M129.314 36.6154C129.314 36.6154 139.397 14.0265 111.733 0.897217C111.733 0.897217 139.914 6.52406 136.898 32.0487L129.314 36.6154Z" fill="#009FE3" />
            <path d="M98.4604 31.804C98.4604 31.804 114.921 50.8047 140 33.7611C140 33.7611 121.557 54.7191 99.1499 40.285L98.4604 31.804Z" fill="#98C21D" />
            <path d="M108.803 16.473C108.803 16.473 114.836 13.2926 122.333 19.001C122.333 19.001 126.125 21.7736 126.125 27.0743C126.212 32.3749 121.385 34.169 121.385 34.169C121.385 34.169 123.712 34.3321 124.919 33.9243C124.919 33.9243 121.041 37.1863 115.525 35.8815C110.01 34.5767 107.855 29.6023 107.855 29.6023C107.855 29.6023 107.51 32.538 108.889 34.169C108.889 34.169 100.53 29.113 106.045 21.1212C106.045 21.1212 108.027 17.9408 112.595 17.6147C112.681 17.6147 111.216 16.3099 108.803 16.473Z" fill="#005286" />
            <path d="M112.422 16.7989C112.422 16.7989 115.18 15.2495 120.092 17.6144C125.004 20.0609 126.211 24.7907 124.315 29.4389C122.505 34.0872 118.627 34.1688 118.627 34.1688C118.627 34.1688 120.523 34.8211 121.729 34.5765C121.729 34.5765 116.3 37.7569 111.388 34.658C111.388 34.658 108.285 32.864 107.251 29.6836C107.251 29.6836 106.648 31.3961 107.337 32.7824C107.337 32.7824 102.683 28.0526 106.648 22.2627C106.648 22.2627 109.664 18.1037 115.438 18.0222C115.438 18.1037 114.059 16.962 112.422 16.7989Z" fill="#FBBA00" />
            <path d="M0.0419922 77.7152C0.0419922 77.7152 0.214354 76.4919 0.214354 75.8395C0.214354 74.3717 0.214354 43.8726 0.214354 43.8726H13.9171C19.088 43.8726 22.449 44.0357 24.0864 44.3619C26.4995 44.8511 28.5679 45.9928 30.1191 47.7053C31.7565 49.4179 32.5322 51.6197 32.5322 54.3108C32.5322 56.3495 32.0151 58.1435 31.1533 59.5299C30.2053 60.9162 28.9988 62.0579 27.5337 62.8733C26.0686 63.6888 24.6035 64.2597 23.1385 64.5043C21.0701 64.8305 18.14 64.9936 14.2618 64.9936H8.66008C8.66008 64.9936 8.66008 65.2382 8.66008 72.1699C8.74626 79.183 0.0419922 77.7152 0.0419922 77.7152ZM8.74626 49.6625V59.2852H13.4C16.7611 59.2852 19.0018 59.1221 20.1221 58.7959C21.2425 58.4697 22.1043 57.8989 22.7937 57.1649C23.397 56.431 23.7417 55.534 23.7417 54.5554C23.7417 53.3322 23.3108 52.272 22.3628 51.5381C21.4148 50.7226 20.2945 50.2333 18.9156 50.0702C17.8814 49.9071 15.8131 49.8256 12.7968 49.8256H8.74626V49.6625Z" fill="#005286" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M50.4571 73.312C54.3353 73.312 55.628 71.1918 55.628 69.5608V64.9126C55.628 64.9126 51.1466 65.4018 48.0441 66.0542C45.2863 66.6251 44.4245 67.4406 44.4245 69.5608C44.4245 72.0073 46.0619 73.312 50.4571 73.312ZM51.1466 50.8862C58.472 50.8862 64.1599 53.1696 64.1599 59.5304V69.8055C64.1599 74.3722 59.5061 77.8788 50.8019 77.8788C41.9252 77.8788 36.2373 75.1877 36.2373 69.0715C36.2373 63.7709 40.374 62.4661 51.7499 60.8351L55.628 60.2643C55.628 56.9208 55.1971 55.4529 49.5092 55.4529C44.4245 55.4529 39.2536 57.0839 39.2536 57.0839C38.3918 55.2898 38.6504 53.1696 39.9431 52.4357C41.4082 51.7833 44.5107 50.8862 51.1466 50.8862Z" fill="#005286" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M219.976 73.312C223.854 73.312 225.147 71.1918 225.147 69.5608V64.9126C225.147 64.9126 220.665 65.4018 217.563 66.0542C214.805 66.6251 213.943 67.4406 213.943 69.5608C213.943 72.0073 215.58 73.312 219.976 73.312ZM220.665 50.8862C227.991 50.8862 233.678 53.1696 233.678 59.5304V69.8055C233.678 74.3722 229.025 77.8788 220.32 77.8788C211.444 77.8788 205.756 75.1877 205.756 69.1531C205.756 63.8524 209.893 62.5477 221.268 60.9167L225.147 60.3458C225.147 57.0024 224.716 55.5345 219.028 55.5345C213.943 55.5345 208.772 57.1655 208.772 57.1655C207.91 55.3714 208.169 53.2511 209.462 52.5172C210.927 51.7833 214.029 50.8862 220.665 50.8862Z" fill="#005286" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M166.543 77.2261C165.336 77.6338 163.526 77.8784 160.252 77.8784C153.874 77.8784 149.996 75.1058 149.996 69.1528V41.4263C149.996 41.4263 150.772 41.1816 152.926 41.1816C156.46 41.1816 158.7 43.5465 158.7 46.4823V68.745C158.7 71.9254 159.648 73.0671 163.871 73.0671C166.198 73.0671 168.266 72.8224 168.266 72.8224C168.784 75.1058 168.094 76.6552 166.543 77.2261Z" fill="#005286" />
            <path d="M173.61 70.5389C177.316 72.0884 181.366 72.9038 186.537 72.9038C191.277 72.9038 192.57 71.5991 192.57 70.2127C192.57 64.5043 172.834 69.5604 172.834 58.2251C172.834 53.4953 177.488 50.5596 186.882 50.5596C190.674 50.5596 193.345 50.8858 197.137 51.9459C202.05 53.3322 198.689 57.8989 198.689 57.8989C194.897 56.6757 190.588 55.6971 187.14 55.6971C181.97 55.6971 181.108 56.5942 181.108 58.1436C181.108 62.9549 201.016 59.0406 201.016 70.0496C201.016 74.6164 196.534 77.8783 186.279 77.8783C181.625 77.8783 179.212 77.3074 176.109 76.3289C176.109 76.3289 171.111 75.1056 173.61 70.5389Z" fill="#005286" />
            <path d="M77.8629 53.0874C79.1556 51.9457 81.3963 50.8041 83.9818 50.8041C86.5672 50.8041 88.2908 51.6196 89.9282 53.169C91.5657 51.7826 95.3576 50.8041 99.2358 50.8041C107.595 50.8041 110.353 54.5553 110.353 59.6113C110.353 59.6113 110.353 64.5042 110.353 72.0882C110.353 79.7538 101.649 77.5519 101.649 77.5519V59.6928C101.649 57.491 100.787 56.594 98.1154 56.594C96.5642 56.594 95.2714 56.9202 94.1511 57.4095C94.2373 57.8988 94.2373 58.3881 94.2373 58.8774C94.2373 58.8774 94.2373 64.9119 94.2373 72.1698C94.2373 79.4276 85.533 77.5519 85.533 77.5519V59.5298C85.533 57.3279 84.585 56.594 81.9996 56.594C80.1898 56.594 78.8971 57.1648 77.9491 57.7357C77.9491 57.7357 77.9491 64.9935 77.9491 72.2513C77.9491 79.5091 69.1586 77.6335 69.1586 77.6335V51.0487C69.0725 51.0487 75.0189 48.8469 77.8629 53.0874Z" fill="#005286" />
            <path d="M114.834 64.0148C114.834 61.7314 115.438 59.5296 116.73 57.4094C118.023 55.2891 119.833 53.6582 122.16 52.5165C124.487 51.3748 127.158 50.804 130.002 50.804C134.484 50.804 138.103 52.1087 140.947 54.6367C143.791 57.1647 145.256 60.3451 145.256 64.2594C145.256 68.1738 143.791 71.4357 140.947 73.9637C138.103 76.5733 134.484 77.7965 130.088 77.7965C127.417 77.7965 124.831 77.2256 122.332 76.1655C119.919 75.1054 118.023 73.556 116.73 71.4357C115.438 69.4785 114.834 66.9505 114.834 64.0148ZM122.849 64.341C122.849 66.869 123.539 68.8262 124.918 70.2125C126.297 71.5988 128.02 72.2512 130.088 72.2512C132.157 72.2512 133.794 71.5988 135.173 70.2125C136.552 68.8262 137.241 66.869 137.241 64.2594C137.241 61.7314 136.552 59.7743 135.173 58.388C133.794 57.0016 132.071 56.3492 130.088 56.3492C128.02 56.3492 126.297 57.0016 124.918 58.388C123.539 59.8558 122.849 61.813 122.849 64.341Z" fill="#005286" />
        </svg>

    );
}

export default LogoUs;