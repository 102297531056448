import React from 'react'

const Call = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.6817 11.9782L16.2217 12.2682C17.2317 12.3882 17.9917 13.2382 17.9817 14.2482V15.9782C17.9817 17.1082 17.0417 18.0482 15.9117 17.9782C7.38174 17.4482 0.55174 10.6282 0.0217399 2.08819C-0.0482601 0.958188 0.89174 0.0181885 2.02174 0.0181885H3.75174C4.77174 0.0181885 5.62174 0.778188 5.74174 1.78819L6.03174 4.30819C6.10174 4.91819 5.89174 5.51819 5.46174 5.94819L3.61174 7.79819C5.05174 10.6382 7.37174 12.9482 10.2017 14.3882L12.0417 12.5482C12.4717 12.1182 13.0717 11.9082 13.6817 11.9782ZM9.87174 2.05819C13.0517 2.45819 15.5417 4.94819 15.9417 8.12819C16.0017 8.63819 16.4317 9.00819 16.9317 9.00819C16.9717 9.00819 17.0117 9.00819 17.0517 8.99819C17.6017 8.92819 17.9917 8.42819 17.9217 7.87819C17.4117 3.78819 14.2017 0.578188 10.1117 0.0681885C9.56174 0.00818848 9.06174 0.398189 9.00174 0.948188C8.93174 1.49819 9.32174 1.99819 9.87174 2.05819ZM10.2517 4.16819C9.72174 4.02819 9.17174 4.34819 9.03174 4.88819C8.89174 5.42819 9.21174 5.96819 9.75174 6.10819C10.8017 6.37819 11.6217 7.19819 11.9017 8.25819C12.0217 8.70819 12.4217 9.00819 12.8717 9.00819C12.9517 9.00819 13.0417 8.99819 13.1217 8.97819C13.6517 8.83819 13.9717 8.28819 13.8417 7.75819C13.3717 5.98819 12.0017 4.61819 10.2517 4.16819Z" fill="black" fillOpacity="0.54" />
        </svg>
    );
}

export default Call;