import React from 'react';

const Play = () => {
    return (
        <svg width="43" height="52" viewBox="0 0 43 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.380371 4.30959V47.0644C0.380371 50.3246 3.97078 52.3056 6.73581 50.531L40.3289 29.1536C42.8875 27.5441 42.8875 23.8299 40.3289 22.1791L6.73581 0.842982C3.97078 -0.931589 0.380371 1.04933 0.380371 4.30959Z" fill="white" />
        </svg>
    );
}

export default Play;