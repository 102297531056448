import React from 'react';

const Book = () => {
    return (
        <svg width="63" height="64" viewBox="0 0 63 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="">
                <path opacity="0.23" d="M31.2 63.3829C48.4313 63.3829 62.4 49.4142 62.4 32.1829C62.4 14.9516 48.4313 0.98291 31.2 0.98291C13.9687 0.98291 0 14.9516 0 32.1829C0 49.4142 13.9687 63.3829 31.2 63.3829Z" fill="#009FE3" />
                <path d="M38.7999 46.5829C39.2999 46.5829 39.7999 46.1829 39.7999 45.5829V16.2829V13.4829C39.7999 13.3829 39.7999 13.3829 39.7999 13.2829C39.6999 12.7829 39.2999 12.4829 38.8999 12.4829H18.8999C18.3999 12.4829 17.8999 12.8829 17.8999 13.4829V45.6829C17.8999 46.1829 18.2999 46.6829 18.8999 46.6829H19.9999H22.9999H38.7999V46.5829Z" fill="white" />
                <path d="M43.9 16.2827H42.8V45.6827C42.8 47.8827 41 49.6827 38.8 49.6827H23V50.1827C23 50.6827 23.4 51.1827 24 51.1827H43.9C44.4 51.1827 44.9 50.7827 44.9 50.1827V17.2827C44.9 16.7827 44.4 16.2827 43.9 16.2827Z" fill="#8DB72E" />
                <path d="M43.8999 13.2829H42.7999C42.6999 11.1829 40.9999 9.48291 38.7999 9.48291H18.8999C16.6999 9.48291 14.8999 11.2829 14.8999 13.4829V45.6829C14.8999 47.8829 16.6999 49.6829 18.8999 49.6829H19.9999V50.1829C19.9999 52.3829 21.7999 54.1829 23.9999 54.1829H43.8999C46.0999 54.1829 47.8999 52.3829 47.8999 50.1829V17.2829C47.8999 15.0829 46.0999 13.2829 43.8999 13.2829ZM19.9999 46.5829H18.8999C18.3999 46.5829 17.8999 46.1829 17.8999 45.5829V13.4829C17.8999 12.9829 18.2999 12.4829 18.8999 12.4829H38.7999C39.2999 12.4829 39.6999 12.8829 39.6999 13.2829C39.6999 13.3829 39.6999 13.3829 39.6999 13.4829V16.2829V45.6829C39.6999 46.1829 39.2999 46.6829 38.6999 46.6829H22.9999H19.9999V46.5829ZM44.8999 50.1829C44.8999 50.6829 44.4999 51.1829 43.8999 51.1829H23.8999C23.3999 51.1829 22.8999 50.7829 22.8999 50.1829V49.6829H38.7999C40.9999 49.6829 42.7999 47.8829 42.7999 45.6829V16.2829H43.8999C44.3999 16.2829 44.8999 16.6829 44.8999 17.2829V50.1829Z" fill="#005286" />
                <path d="M36.2999 19.7827H21.8999C21.0999 19.7827 20.3999 19.0827 20.3999 18.2827C20.3999 17.4827 21.0999 16.7827 21.8999 16.7827H36.3999C37.1999 16.7827 37.8999 17.4827 37.8999 18.2827C37.8999 19.0827 37.0999 19.7827 36.2999 19.7827Z" fill="#005286" />
                <path d="M36.2999 26.9829H21.8999C21.0999 26.9829 20.3999 26.2829 20.3999 25.4829C20.3999 24.6829 20.9999 23.9829 21.8999 23.9829H36.3999C37.1999 23.9829 37.8999 24.6829 37.8999 25.4829C37.8999 26.2829 37.0999 26.9829 36.2999 26.9829Z" fill="#005286" />
                <path d="M28.3998 34.4829H21.7998C20.9998 34.4829 20.2998 33.7829 20.2998 32.9829C20.2998 32.1829 20.9998 31.4829 21.7998 31.4829H28.3998C29.1998 31.4829 29.8998 32.1829 29.8998 32.9829C29.8998 33.7829 29.1998 34.4829 28.3998 34.4829Z" fill="#005286" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="62.4" height="62.4" fill="white" transform="translate(0 0.98291)" />
                </clipPath>
            </defs>
        </svg>


    );
}

export default Book;