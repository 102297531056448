import React from 'react'

const ArrowBack = () => {
    return (
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99999 9.8751L3.11999 5.9951L6.99999 2.1151C7.18725 1.92827 7.29248 1.67462 7.29248 1.4101C7.29248 1.14558 7.18725 0.891929 6.99999 0.705098C6.60999 0.315098 5.97999 0.315098 5.58999 0.705098L0.999995 5.2951C0.609995 5.6851 0.609995 6.3151 0.999994 6.7051L5.58999 11.2951C5.97999 11.6851 6.60999 11.6851 6.99999 11.2951C7.37999 10.9051 7.38999 10.2651 6.99999 9.8751Z" fill="#767676" />
        </svg>

    );
}

export default ArrowBack;