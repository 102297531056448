import React from 'react'

const House = () => {
    return (
        <svg width="87" height="87" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.23" d="M43.3068 86.5196C67.2245 86.5196 86.6136 67.1516 86.6136 43.2598C86.6136 19.3681 67.2245 0 43.3068 0C19.3891 0 0 19.3681 0 43.2598C0 67.1516 19.3891 86.5196 43.3068 86.5196Z" fill="#24BDBC" />
            <path d="M51.6331 43.6781H37.1582V68.4178H51.6331V43.6781Z" fill="#24BDBC" />
            <path d="M66.8513 20.2742H61.9219V24.3476L66.8513 29.1844V20.2742Z" fill="white" />
            <path d="M20.6455 35.9096V68.4192H32.9969V41.5999C32.9969 40.4513 33.929 39.5216 35.0774 39.5216H53.7134C54.8618 39.5216 55.7939 40.4513 55.7939 41.5999V68.4178H68.0343V36.1687L44.1637 12.7535L20.6455 35.9096Z" fill="white" />
            <path d="M71.0125 33.2661V18.1959C71.0125 17.0473 70.0804 16.1176 68.932 16.1176H59.8401C58.6916 16.1176 57.7596 17.0473 57.7596 18.1959V20.2658L45.619 8.35597C44.8076 7.56069 43.5094 7.56208 42.7007 8.35874L10.4415 40.1202C9.62318 40.9266 9.61347 42.2414 10.4193 43.0589C10.8271 43.4718 11.3639 43.6782 11.9007 43.6782C12.4277 43.6782 12.9562 43.4787 13.3612 43.0797L16.4834 40.0052V70.4962C16.4834 71.6448 17.4154 72.5744 18.5639 72.5744H70.1137C71.2622 72.5744 72.1942 71.6448 72.1942 70.4962V40.2504L75.021 43.0229C75.8421 43.8278 77.157 43.8168 77.9628 42.9951C78.7673 42.1763 78.7548 40.8601 77.9351 40.0565L71.0125 33.2661ZM61.922 20.2741H66.8528V29.183L61.922 24.3462V20.2741ZM51.6331 68.4193H37.1582V43.6782H51.6331V68.4193ZM68.0346 68.4193H55.7942V41.5999C55.7942 40.4513 54.8621 39.5217 53.7136 39.5217H35.0777C33.9292 39.5217 32.9972 40.4513 32.9972 41.5999V68.4179H20.6458V35.9096L44.164 12.7536L68.0346 36.1687V68.4193Z" fill="#005286" />
        </svg>
    );
}

export default House;