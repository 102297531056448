import React from 'react'

const Phone = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8142 10.1788L11.1208 9.98545C10.7142 9.93878 10.3142 10.0788 10.0275 10.3654L8.80083 11.5921C6.91417 10.6321 5.3675 9.09211 4.4075 7.19878L5.64083 5.96545C5.9275 5.67878 6.0675 5.27878 6.02083 4.87212L5.8275 3.19212C5.7475 2.51878 5.18083 2.01212 4.50083 2.01212H3.3475C2.59417 2.01212 1.9675 2.63878 2.01417 3.39212C2.3675 9.08545 6.92084 13.6321 12.6075 13.9854C13.3608 14.0321 13.9875 13.4054 13.9875 12.6521V11.4988C13.9942 10.8254 13.4875 10.2588 12.8142 10.1788Z" fill="#4D4D4C" />
        </svg>
    );
}

export default Phone;