import React from 'react'

const ArrowFooter = () => {
    return (
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.8751 1.00001L5.9951 4.88001L2.1151 1.00001C1.92827 0.812752 1.67462 0.70752 1.4101 0.70752C1.14558 0.70752 0.891929 0.812752 0.705098 1.00001C0.315098 1.39001 0.315098 2.02001 0.705098 2.41001L5.2951 7.00001C5.6851 7.39001 6.3151 7.39001 6.7051 7.00001L11.2951 2.41001C11.6851 2.02001 11.6851 1.39001 11.2951 1.00001C10.9051 0.620005 10.2651 0.610005 9.8751 1.00001Z" fill="#767676" />
        </svg>
    );
}

export default ArrowFooter;