import React from 'react'

const DeleteCart = () => {
    return (
        <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.97949 0.833333H9.75033C10.1399 0.833333 10.4587 1.15208 10.4587 1.54167C10.4587 1.93125 10.1399 2.25 9.75033 2.25H1.25033C0.860742 2.25 0.541992 1.93125 0.541992 1.54167C0.541992 1.15208 0.860742 0.833333 1.25033 0.833333H3.02116L3.52408 0.330417C3.65158 0.202917 3.83574 0.125 4.01991 0.125H6.98074C7.16491 0.125 7.34908 0.202917 7.47658 0.330417L7.97949 0.833333ZM2.66699 12.875C1.88783 12.875 1.25033 12.2375 1.25033 11.4583V4.375C1.25033 3.59583 1.88783 2.95833 2.66699 2.95833H8.33366C9.11283 2.95833 9.75033 3.59583 9.75033 4.375V11.4583C9.75033 12.2375 9.11283 12.875 8.33366 12.875H2.66699Z" fill="#C4C4C4" />
        </svg>
    );
}

export default DeleteCart;