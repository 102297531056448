import React from 'react';

const Planta = () => {
    return (
        <svg width="64" height="72" viewBox="0 0 64 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="">
                <path opacity="0.23" d="M32.3001 71.6827C49.5314 71.6827 63.5001 57.714 63.5001 40.4827C63.5001 23.2514 49.5314 9.28271 32.3001 9.28271C15.0688 9.28271 1.1001 23.2514 1.1001 40.4827C1.1001 57.714 15.0688 71.6827 32.3001 71.6827Z" fill="#009FE3" />
                <path d="M27.6001 49.583H22.6001V52.583H27.6001V49.583Z" fill="white" />
                <path d="M14.5 49.583H9.5V52.583H14.5V49.583Z" fill="white" />
                <path d="M41.0999 48.1826H36.3999V60.5826H41.0999V48.1826Z" fill="#FBBA00" />
                <path d="M54.5 8.68262H51.3L49.5 34.8826H56.2L54.5 8.68262Z" fill="#8DB72E" />
                <path d="M42.3 36.383V31.583L34.1 39.083C33.7 39.483 33 39.583 32.5 39.383C32 39.183 31.6 38.583 31.6 37.983V32.983L24.8 39.783C24.4 40.183 23.7 40.383 23.2 40.083C22.6 39.883 22.3 39.283 22.3 38.683V32.483L14 39.083C13.5 39.483 12.9 39.483 12.4 39.283C11.9 39.083 11.5 38.483 11.5 37.883V31.983L3 37.283V60.583H33.4V46.683C33.4 45.883 34.1 45.183 34.9 45.183H42.6C43.4 45.183 44.1 45.883 44.1 46.683V60.583H59.3V37.883H43.8C43 37.883 42.3 37.283 42.3 36.383ZM17.5 54.183C17.5 54.983 16.8 55.683 16 55.683H8C7.2 55.683 6.5 54.983 6.5 54.183V48.183C6.5 47.383 7.2 46.683 8 46.683H16C16.8 46.683 17.5 47.383 17.5 48.183V54.183ZM30.6 54.183C30.6 54.983 29.9 55.683 29.1 55.683H21.1C20.3 55.683 19.6 54.983 19.6 54.183V48.183C19.6 47.383 20.3 46.683 21.1 46.683H29.1C29.9 46.683 30.6 47.383 30.6 48.183V54.183Z" fill="white" />
                <path d="M60.9 34.8826H59.2L57.4 7.08262C57.3 6.28262 56.7 5.68262 55.9 5.68262H49.9C49.1 5.68262 48.5 6.28262 48.4 7.08262L46.5 34.8826H45.3V28.1826C45.3 27.5826 45 27.0826 44.4 26.7826C43.9 26.5826 43.2 26.6826 42.8 27.0826L34.6 34.5826V29.3826C34.6 28.7826 34.2 28.1826 33.7 27.9826C33.1 27.7826 32.5 27.8826 32.1 28.2826L25.3 35.0826V29.3826C25.3 28.7826 25 28.2826 24.4 27.9826C23.9 27.7826 23.3 27.7826 22.8 28.1826L14.6 34.7826V29.2826C14.6 28.7826 14.3 28.2826 13.8 27.9826C13.3 27.6826 12.7 27.6826 12.3 27.9826L0.7 35.0826C0.3 35.3826 0 35.8826 0 36.3826V62.0826C0 62.8826 0.7 63.5826 1.5 63.5826H60.9C61.7 63.5826 62.4 62.8826 62.4 62.0826V36.3826C62.4 35.5826 61.8 34.8826 60.9 34.8826ZM51.3 8.68262H54.5L56.3 34.8826H49.6L51.3 8.68262ZM41.2 60.5826H36.5V48.1826H41.2V60.5826ZM59.4 60.5826H44.2V46.6826C44.2 45.8826 43.5 45.1826 42.7 45.1826H35C34.2 45.1826 33.5 45.8826 33.5 46.6826V60.5826H3V37.2826L11.6 32.0826V37.9826C11.6 38.5826 11.9 39.0826 12.5 39.3826C13 39.5826 13.6 39.5826 14.1 39.1826L22.3 32.5826V38.7826C22.3 39.3826 22.7 39.9826 23.2 40.1826C23.8 40.3826 24.4 40.2826 24.8 39.8826L31.6 33.0826V38.0826C31.6 38.6826 31.9 39.1826 32.5 39.4826C33 39.6826 33.7 39.5826 34.1 39.1826L42.3 31.6826V36.4826C42.3 37.2826 43 37.9826 43.8 37.9826H59.4V60.5826Z" fill="#005286" />
                <path d="M34.5001 4.08282C34.5001 4.08282 35.2001 3.78282 35.8001 4.28282C35.9001 4.38282 35.9001 4.38282 36.0001 4.38282C36.2001 4.48282 37.4001 5.28282 39.1001 5.28282C39.6001 5.28282 40.1001 5.18282 40.6001 5.08282C40.7001 5.08282 40.7001 5.08282 40.8001 4.98282C41.8001 4.58282 43.0001 4.48282 43.2001 4.58282C43.7001 5.28282 44.6001 5.38282 45.3001 4.88282C46.0001 4.38282 46.1001 3.48282 45.6001 2.78282C44.0001 0.682822 40.7001 1.78282 39.8001 2.08282C38.7001 2.28282 37.9001 1.98282 37.7001 1.78282C36.1001 0.382822 34.1001 0.782822 33.2001 1.28282C32.5001 1.68282 32.2001 2.58282 32.5001 3.28282C32.9001 4.18282 33.8001 4.48282 34.5001 4.08282Z" fill="#005286" />
                <path d="M16 46.583H8C7.2 46.583 6.5 47.283 6.5 48.083V54.083C6.5 54.883 7.2 55.583 8 55.583H16C16.8 55.583 17.5 54.883 17.5 54.083V48.083C17.5 47.283 16.8 46.583 16 46.583ZM14.5 52.683H9.5V49.683H14.5V52.683Z" fill="#005286" />
                <path d="M29.1001 46.583H21.1001C20.3001 46.583 19.6001 47.283 19.6001 48.083V54.083C19.6001 54.883 20.3001 55.583 21.1001 55.583H29.1001C29.9001 55.583 30.6001 54.883 30.6001 54.083V48.083C30.6001 47.283 29.9001 46.583 29.1001 46.583ZM27.6001 52.683H22.6001V49.683H27.6001V52.683Z" fill="#005286" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="63.5" height="70.7" fill="white" transform="translate(0 0.98291)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Planta;