import React from 'react'

const MarkerSearch = () => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.0415 11.1952C6.0415 6.51896 9.82359 2.73688 14.4998 2.73688C19.1761 2.73688 22.9582 6.51896 22.9582 11.1952C22.9582 16.234 17.6173 23.1819 15.4303 25.8281C14.9469 26.4081 14.0648 26.4081 13.5815 25.8281C11.3823 23.1819 6.0415 16.234 6.0415 11.1952ZM11.479 11.1952C11.479 12.8627 12.8323 14.216 14.4998 14.216C16.1673 14.216 17.5207 12.8627 17.5207 11.1952C17.5207 9.52771 16.1673 8.17438 14.4998 8.17438C12.8323 8.17438 11.479 9.52771 11.479 11.1952Z" fill="#009FE3" />
        </svg>
    );
}

export default MarkerSearch;