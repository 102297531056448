import React from 'react';

const Logo = () => {
    return (
        <svg width="131" height="44" viewBox="0 0 131 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M79.3444 18.2911L77.5082 18.9822C79.2961 2.90262 63.7848 0.460733 63.5915 0.41466L61.0305 0L63.3499 1.29005L61.6587 1.98115C53.7822 5.48272 51.8494 11.334 51.6561 15.8492C51.6561 15.8492 51.5111 21.2859 53.8306 24.9257L54.1688 22.8984C57.358 25.3864 61.5137 26.6304 64.7996 26.5382C68.5203 26.4921 71.5163 25.3403 73.7391 24.0042C73.6908 24.0963 77.5565 21.9309 79.3444 18.2911Z" fill="white" />
            <path d="M66.4906 4.97592C66.4906 4.97592 52.0908 6.95707 53.4921 23.4513C53.4921 23.4513 48.1767 8.89215 61.9967 2.81047L66.4906 4.97592Z" fill="#005286" />
            <path d="M72.4826 20.6869C72.4826 20.6869 78.1363 7.92461 62.625 0.506805C62.625 0.506805 78.4262 3.68586 76.7349 18.1068L72.4826 20.6869Z" fill="#009FE3" />
            <path d="M55.1836 17.9686C55.1836 17.9686 64.413 28.7037 78.4746 19.0743C78.4746 19.0743 68.1338 30.9152 55.5702 22.7602L55.1836 17.9686Z" fill="#98C21D" />
            <path d="M60.9822 9.3068C60.9822 9.3068 64.3648 7.50994 68.5687 10.7351C68.5687 10.7351 70.6949 12.3016 70.6949 15.2963C70.7432 18.2911 68.0372 19.3047 68.0372 19.3047C68.0372 19.3047 69.3419 19.3969 70.0184 19.1665C70.0184 19.1665 67.8439 21.0094 64.7513 20.2722C61.6587 19.5351 60.4507 16.7246 60.4507 16.7246C60.4507 16.7246 60.2574 18.3832 61.0306 19.3047C61.0306 19.3047 56.3434 16.4482 59.4359 11.933C59.4359 11.933 60.5473 10.1361 63.1084 9.95183C63.1567 9.95183 62.3352 9.21465 60.9822 9.3068Z" fill="#005286" />
            <path d="M63.0118 9.4911C63.0118 9.4911 64.5581 8.6157 67.3124 9.95183C70.0667 11.334 70.7432 14.0063 69.6801 16.6325C68.6654 19.2586 66.4909 19.3047 66.4909 19.3047C66.4909 19.3047 67.554 19.6733 68.2305 19.5351C68.2305 19.5351 65.1862 21.3319 62.4319 19.5811C62.4319 19.5811 60.6923 18.5675 60.1125 16.7707C60.1125 16.7707 59.7742 17.7382 60.1608 18.5215C60.1608 18.5215 57.5514 15.8492 59.7742 12.578C59.7742 12.578 61.4655 10.2283 64.703 10.1822C64.703 10.2283 63.9299 9.58324 63.0118 9.4911Z" fill="#FBBA00" />
            <path d="M0 43.9078C0 43.9078 0.0966433 43.2167 0.0966433 42.8482C0.0966433 42.0188 0.0966433 24.7874 0.0966433 24.7874H7.77979C10.6791 24.7874 12.5636 24.8796 13.4817 25.0639C14.8347 25.3403 15.9945 25.9853 16.8643 26.9529C17.7824 27.9204 18.2173 29.1644 18.2173 30.6848C18.2173 31.8366 17.9273 32.8503 17.4441 33.6335C16.9126 34.4167 16.2361 35.0618 15.4146 35.5225C14.5931 35.9832 13.7717 36.3058 12.9502 36.444C11.7905 36.6283 10.1475 36.7204 7.97307 36.7204H4.83216C4.83216 36.7204 4.83216 36.8586 4.83216 40.7749C4.88049 44.7372 0 43.9078 0 43.9078ZM4.88049 28.0586V33.4953H7.48986C9.3744 33.4953 10.6308 33.4031 11.2589 33.2188C11.8871 33.0345 12.3703 32.712 12.7569 32.2974C13.0952 31.8827 13.2885 31.3759 13.2885 30.823C13.2885 30.1319 13.0468 29.533 12.5153 29.1183C11.9838 28.6576 11.3556 28.3811 10.5824 28.289C10.0026 28.1969 8.84286 28.1508 7.1516 28.1508H4.88049V28.0586Z" fill="#005286" />
            <path fillRule="evenodd" clipRule="evenodd" d="M28.268 41.4199C30.4425 41.4199 31.1673 40.222 31.1673 39.3005V36.6743C31.1673 36.6743 28.6546 36.9508 26.915 37.3194C25.3687 37.6419 24.8855 38.1026 24.8855 39.3005C24.8855 40.6827 25.8036 41.4199 28.268 41.4199ZM28.6546 28.7497C32.7619 28.7497 35.9511 30.0398 35.9511 33.6335V39.4387C35.9511 42.0188 33.3418 44 28.4613 44C23.4842 44 20.2949 42.4796 20.2949 39.0241C20.2949 36.0293 22.6144 35.2921 28.9928 34.3707L31.1673 34.0482C31.1673 32.1592 30.9257 31.3298 27.7365 31.3298C24.8855 31.3298 21.9862 32.2513 21.9862 32.2513C21.503 31.2377 21.6479 30.0398 22.3728 29.6251C23.1942 29.2565 24.9338 28.7497 28.6546 28.7497Z" fill="#005286" />
            <path fillRule="evenodd" clipRule="evenodd" d="M123.317 41.4199C125.491 41.4199 126.216 40.222 126.216 39.3005V36.6743C126.216 36.6743 123.703 36.9508 121.964 37.3194C120.418 37.6419 119.934 38.1026 119.934 39.3005C119.934 40.6827 120.852 41.4199 123.317 41.4199ZM123.703 28.7497C127.811 28.7497 131 30.0398 131 33.6335V39.4387C131 42.0188 128.391 44 123.51 44C118.533 44 115.344 42.4796 115.344 39.0702C115.344 36.0754 117.663 35.3382 124.042 34.4168L126.216 34.0942C126.216 32.2052 125.975 31.3759 122.785 31.3759C119.934 31.3759 117.035 32.2974 117.035 32.2974C116.552 31.2838 116.697 30.0859 117.422 29.6712C118.243 29.2565 119.983 28.7497 123.703 28.7497Z" fill="#005286" />
            <path fillRule="evenodd" clipRule="evenodd" d="M93.3573 43.6314C92.6808 43.8618 91.6661 44 89.8299 44C86.2541 44 84.0796 42.4335 84.0796 39.0702V23.4052C84.0796 23.4052 84.5145 23.267 85.7225 23.267C87.7037 23.267 88.9601 24.6031 88.9601 26.2618V38.8398C88.9601 40.6367 89.4916 41.2817 91.8594 41.2817C93.1641 41.2817 94.3238 41.1435 94.3238 41.1435C94.6137 42.4335 94.2271 43.3089 93.3573 43.6314Z" fill="#005286" />
            <path d="M97.3197 39.8534C99.3975 40.7288 101.669 41.1895 104.568 41.1895C107.226 41.1895 107.95 40.4524 107.95 39.6691C107.95 36.444 96.8848 39.3005 96.8848 32.8963C96.8848 30.2241 99.4941 28.5654 104.761 28.5654C106.887 28.5654 108.385 28.7497 110.511 29.3487C113.266 30.1319 111.381 32.712 111.381 32.712C109.255 32.0209 106.839 31.4681 104.906 31.4681C102.007 31.4681 101.524 31.9749 101.524 32.8503C101.524 35.5686 112.686 33.3571 112.686 39.577C112.686 42.1571 110.173 44 104.423 44C101.814 44 100.461 43.6775 98.721 43.1246C98.721 43.1246 95.9183 42.4335 97.3197 39.8534Z" fill="#005286" />
            <path d="M43.6344 29.9937C44.3593 29.3487 45.6156 28.7037 47.0653 28.7037C48.5149 28.7037 49.4814 29.1644 50.3995 30.0398C51.3176 29.2565 53.4437 28.7037 55.6182 28.7037C60.3054 28.7037 61.8517 30.823 61.8517 33.6796C61.8517 33.6796 61.8517 36.444 61.8517 40.7288C61.8517 45.0597 56.9712 43.8157 56.9712 43.8157V33.7257C56.9712 32.4817 56.488 31.9749 54.99 31.9749C54.1202 31.9749 53.3954 32.1592 52.7672 32.4356C52.8155 32.712 52.8155 32.9885 52.8155 33.2649C52.8155 33.2649 52.8155 36.6743 52.8155 40.7749C52.8155 44.8754 47.9351 43.8157 47.9351 43.8157V33.6335C47.9351 32.3895 47.4035 31.9749 45.9539 31.9749C44.9391 31.9749 44.2143 32.2974 43.6828 32.6199C43.6828 32.6199 43.6828 36.7204 43.6828 40.8209C43.6828 44.9215 38.7539 43.8618 38.7539 43.8618V28.8419C38.7056 28.8419 42.0398 27.5979 43.6344 29.9937Z" fill="#005286" />
            <path d="M64.3643 36.1675C64.3643 34.8775 64.7025 33.6335 65.4273 32.4356C66.1522 31.2377 67.1669 30.3162 68.4716 29.6712C69.7763 29.0262 71.2743 28.7037 72.8689 28.7037C75.3816 28.7037 77.4111 29.4408 79.0057 30.8691C80.6003 32.2974 81.4218 34.0942 81.4218 36.3058C81.4218 38.5173 80.6003 40.3602 79.0057 41.7885C77.4111 43.2628 75.3816 43.9539 72.9172 43.9539C71.4192 43.9539 69.9696 43.6314 68.5682 43.0325C67.2152 42.4335 66.1522 41.5581 65.4273 40.3602C64.7025 39.2544 64.3643 37.8262 64.3643 36.1675ZM68.8582 36.3518C68.8582 37.7801 69.2447 38.8859 70.0179 39.6691C70.791 40.4523 71.7575 40.8209 72.9172 40.8209C74.0769 40.8209 74.995 40.4523 75.7682 39.6691C76.5413 38.8859 76.9279 37.7801 76.9279 36.3058C76.9279 34.8775 76.5413 33.7717 75.7682 32.9885C74.995 32.2052 74.0286 31.8366 72.9172 31.8366C71.7575 31.8366 70.791 32.2052 70.0179 32.9885C69.2447 33.8178 68.8582 34.9236 68.8582 36.3518Z" fill="#005286" />
        </svg>
    );
}

export default Logo;