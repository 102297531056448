import React from 'react'

const Chart = () => {
    return (
        <svg width="87" height="86" viewBox="0 0 71 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path opacity="0.23" d="M35.0209 62.4631C52.2648 62.4631 66.2439 48.4841 66.2439 31.2401C66.2439 13.9961 52.2648 0.0170898 35.0209 0.0170898C17.7769 0.0170898 3.79785 13.9961 3.79785 31.2401C3.79785 48.4841 17.7769 62.4631 35.0209 62.4631Z" fill="#009FE3" />
                <path d="M29.593 31.9541H23.959V51.5321H29.722C29.64 51.3471 29.593 51.1431 29.593 50.9281V31.9541Z" fill="#009FE3" />
                <path d="M45.3059 33.7041H39.6719V51.5321H45.3059V33.7041Z" fill="#8DB72E" />
                <path d="M15.406 38.8092H9.77197V51.5332H15.406V38.8092Z" fill="white" />
                <path d="M60.7678 23.7141H55.1338V51.5321H60.7678V23.7141Z" fill="#FBBA00" />
                <path d="M68.543 51.5321H63.768V22.2141C63.768 21.3861 63.096 20.7141 62.268 20.7141H53.634C52.806 20.7141 52.134 21.3861 52.134 22.2141V51.5321H48.306V32.2041C48.306 31.3761 47.634 30.7041 46.806 30.7041H38.172C37.344 30.7041 36.672 31.3761 36.672 32.2041V51.5321H32.464C32.546 51.3471 32.593 51.1431 32.593 50.9281V30.4541C32.593 29.6261 31.921 28.9541 31.093 28.9541H22.459C21.631 28.9541 20.959 29.6261 20.959 30.4541V51.5321H18.406V37.3091C18.406 36.4811 17.734 35.8091 16.906 35.8091H8.272C7.444 35.8091 6.772 36.4811 6.772 37.3091V51.5331H1.5C0.672 51.5331 0 52.2051 0 53.0331C0 53.8611 0.672 54.5331 1.5 54.5331H68.543C69.371 54.5331 70.043 53.8611 70.043 53.0331C70.043 52.2051 69.371 51.5321 68.543 51.5321ZM15.406 51.5321H9.772V38.8091H15.406V51.5321ZM23.959 51.5321V31.9541H29.593V50.9281C29.593 51.1431 29.64 51.3471 29.722 51.5321H23.959ZM45.306 51.5321H39.672V33.7041H45.306V51.5321ZM60.768 51.5321H55.134V23.7141H60.768V51.5321Z" fill="#005286" />
                <path d="M8.16995 29.0451C8.20595 29.0481 8.70095 29.0771 9.56895 29.0771C15.1749 29.0771 36.3249 27.8511 49.6679 10.3091L49.929 12.0381C50.041 12.7811 50.681 13.3131 51.41 13.3131C51.484 13.3131 51.56 13.3081 51.637 13.2961C52.455 13.1721 53.02 12.4071 52.896 11.5881L51.964 5.42315C51.895 4.96415 51.6159 4.56315 51.2099 4.33715C50.805 4.11015 50.3149 4.08615 49.8909 4.26915L44.4179 6.61215C43.6559 6.93815 43.303 7.82015 43.629 8.58115C43.954 9.34315 44.8349 9.69515 45.5979 9.37015L47.0979 8.72815C32.666 27.4251 8.62595 26.0761 8.37295 26.0521C7.55195 25.9981 6.83195 26.6221 6.77595 27.4481C6.71995 28.2741 7.34495 28.9891 8.16995 29.0451Z" fill="#005286" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="87" height="86" fill="white" transform="translate(0 0.0170898)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Chart;