import React from 'react';

const Vasito = () => {
    return (
        <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.23" d="M47 94C72.9574 94 94 72.9574 94 47C94 21.0426 72.9574 0 47 0C21.0426 0 0 21.0426 0 47C0 72.9574 21.0426 94 47 94Z" fill="#009FE3" />
            <path d="M35.7015 72.4583C35.7015 72.7596 36.0028 72.9102 36.304 72.9102H56.6406C56.9419 72.9102 57.2431 72.7596 57.2431 72.4583L58.7495 62.2147H34.3457L35.7015 72.4583Z" fill="white" />
            <path d="M29.8262 30.5801L31.4832 42.0288H61.6114L63.2685 30.5801H30.1275C29.9768 30.5801 29.9768 30.5801 29.8262 30.5801Z" fill="white" />
            <path d="M33.7432 58.5994H59.2016L61.1599 45.6443H31.9355L33.7432 58.5994Z" fill="#8DB72E" />
            <path d="M63.8709 21.8429H30.1273C28.7715 21.8429 27.5664 23.048 27.5664 24.4038C27.5664 25.3076 28.0183 26.0608 28.6209 26.5128C29.0728 26.814 29.5247 26.9647 30.1273 26.9647H63.8709C65.2267 26.9647 66.4318 25.7596 66.4318 24.4038C66.5824 23.048 65.3773 21.8429 63.8709 21.8429Z" fill="#FBBA00" />
            <path d="M63.871 18.2276H48.355V4.51924C48.355 3.46475 47.6018 2.71155 46.5473 2.71155H29.8262C28.7717 2.71155 28.0185 3.46475 28.0185 4.51924C28.0185 5.57373 28.7717 6.32693 29.8262 6.32693H44.7396V18.2276H30.1275C26.6627 18.2276 23.9512 20.9391 23.9512 24.4039C23.9512 26.2115 24.7044 27.8686 25.9095 28.9231L32.0858 72.9103C32.3871 75.0192 34.1948 76.5257 36.3037 76.5257H56.6403C58.7492 76.5257 60.5569 75.0192 60.8582 72.9103L67.0345 29.6763C68.8422 28.6218 70.198 26.6635 70.198 24.4039C70.0473 20.9391 67.3358 18.2276 63.871 18.2276ZM57.2428 72.4583C57.2428 72.7596 56.9416 72.9103 56.6403 72.9103H36.3037C36.0025 72.9103 35.7012 72.7596 35.7012 72.4583L34.1948 62.2148H58.7492L57.2428 72.4583ZM59.2012 58.5994H33.7428L31.9351 45.4936H61.1595L59.2012 58.5994ZM61.6114 42.0289H31.4832L29.8262 30.5801C29.9768 30.5801 29.9768 30.5801 30.1275 30.5801H63.2685L61.6114 42.0289ZM63.871 26.9648H30.1275C29.6755 26.9648 29.073 26.8141 28.621 26.5128C27.8678 26.0609 27.5666 25.3077 27.5666 24.4039C27.5666 23.0481 28.7717 21.843 30.1275 21.843H63.871C65.2268 21.843 66.4319 23.0481 66.4319 24.4039C66.5826 25.9103 65.3775 26.9648 63.871 26.9648Z" fill="#005286" />
            <path d="M6.17731 55.7371C5.57474 55.7371 4.97218 55.4358 4.52025 54.9839C3.76705 54.08 3.76705 52.5736 4.82154 51.8204L10.2446 47.1506C10.9978 46.548 12.0523 46.3974 12.9562 46.9999L19.2831 50.9166C20.3376 51.5191 20.6388 53.0256 20.0363 54.08C19.4337 55.1345 17.9273 55.4358 16.8728 54.8333L12.0523 51.8204L7.985 55.2852C7.23179 55.5865 6.77987 55.7371 6.17731 55.7371Z" fill="#005286" />
            <path d="M45.1936 89.4807C45.1936 89.4807 45.043 89.4807 45.1936 89.4807C35.1007 89.1794 25.9116 84.9615 18.9821 77.58C12.2032 70.1986 8.58785 60.7083 9.03977 50.7659C9.03977 49.5608 10.0943 48.5063 11.45 48.657C12.6552 48.657 13.7096 49.7115 13.559 51.0672C13.2577 59.8044 16.4212 68.2403 22.4468 74.7179C28.4725 81.1954 36.6071 84.8108 45.3443 85.1121C46.5494 85.1121 47.6039 86.1666 47.4532 87.5224C47.4532 88.4262 46.3987 89.4807 45.1936 89.4807Z" fill="#005286" />
            <path d="M83.7564 54.6827C83.1538 54.6827 82.7019 54.5321 82.25 54.0802L76.8269 49.109C75.9231 48.2052 75.9231 46.8494 76.6763 45.9456C77.5801 45.0417 78.9359 45.0417 79.8397 45.7949L84.0577 49.7116L88.7276 47C89.782 46.3975 91.1378 46.6988 91.7404 47.9039C92.3429 48.9584 92.0417 50.3142 90.8365 50.9167L84.6602 54.5321C84.5096 54.6827 84.2083 54.6827 83.7564 54.6827Z" fill="#005286" />
            <path d="M84.5088 52.875C84.3582 52.875 84.2075 52.875 84.0569 52.875C82.8518 52.5737 82.0985 51.3686 82.3998 50.1635C84.3582 41.5769 82.7011 32.6891 78.0312 25.3077C73.3614 17.9263 65.9799 12.8045 57.3934 10.8462C56.1883 10.5449 55.4351 9.33975 55.7364 8.13462C56.0376 6.92949 57.2428 6.17629 58.4479 6.47757C68.2396 8.58654 76.5248 14.4615 81.9479 22.8974C87.371 31.3333 89.028 41.4263 86.9191 51.0673C86.4671 52.2724 85.5633 52.875 84.5088 52.875Z" fill="#005286" />
        </svg>

    );
}

export default Vasito;