import React from 'react';

const Cartel = () => {
    return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="26" cy="26" r="25.4348" stroke="#005286" stroke-width="1.13043" />
            <g clip-path="url(#clip0)">
                <path d="M21.8374 15.7593V15.7161C21.919 14.679 22.1639 13.9013 22.4904 13.3395C21.1843 13.642 20.8986 14.8087 20.8578 15.0247L20.5312 20.0803H21.8374V15.7593Z" fill="white" />
                <path d="M25.3869 13.2963H23.9583C23.6726 13.5987 23.1012 14.3765 23.0195 15.8024V20.0802H25.5093L25.3869 13.2963Z" fill="white" />
                <path d="M28.899 20.0802L28.7765 15.5864C28.7357 14.1173 28.001 13.5123 27.7153 13.2963H26.6133L26.7357 20.0802H28.899Z" fill="white" />
                <path d="M30.1216 20.0803H31.4685L31.0604 15.3704C30.9379 13.7716 29.8767 13.426 29.4277 13.3395C29.7543 13.8581 29.9992 14.5926 30.04 15.5432L30.1216 20.0803Z" fill="white" />
                <path d="M21.4687 38.7037H30.5707L31.2238 24.0123H20.6523L21.4687 38.7037Z" fill="#FBBA00" />
                <path d="M32.6124 21.3765H26.1634H19.4287C19.3471 21.3765 19.2246 21.4629 19.2246 21.5926V22.5864C19.2246 22.6728 19.3062 22.8025 19.4287 22.8025H32.6124C32.694 22.8025 32.8164 22.716 32.8164 22.5864V21.5926C32.7756 21.4629 32.694 21.3765 32.6124 21.3765Z" fill="#8DB72E" />
                <path d="M32.6939 20.0802L32.2449 15.1975C32.0408 12.6914 30.1224 12 29.1837 12H26.0408H23.102C20.6939 12 19.7959 13.858 19.6327 14.8519V14.8951L19.2653 20.0802C18.5714 20.1235 18 20.7716 18 21.5494V22.5432C18 23.3642 18.6122 24.0556 19.4286 24.0556H19.4694L20.3265 39.4383C20.3265 39.7839 20.6122 40.0432 20.9388 40.0432H31.1837C31.5102 40.0432 31.7959 39.7839 31.7959 39.4383L32.4898 24.0556H32.6531C33.4286 24.0556 34.0816 23.4074 34.0816 22.5432V21.5494C34 20.7716 33.4286 20.1235 32.6939 20.0802ZM31.0204 15.3272L31.4286 20.037H30.1224L30 15.5432C29.9592 14.5926 29.7143 13.858 29.3878 13.3395C29.8776 13.3827 30.898 13.7716 31.0204 15.3272ZM27.7143 13.2963C28 13.4691 28.7347 14.1173 28.7755 15.5864L28.898 20.0802H26.7347L26.6122 13.2963H27.7143ZM23.0612 15.8025C23.1429 14.3765 23.7143 13.5988 24 13.2963H25.4286L25.551 20.0802H23.0612V15.8025ZM20.8571 15.0247C20.898 14.8086 21.1837 13.642 22.449 13.3395C22.1633 13.9012 21.8776 14.679 21.7959 15.716V15.7593V20.037H20.5306L20.8571 15.0247ZM30.5714 38.7037H21.4694L20.6531 24.0123H31.2245L30.5714 38.7037ZM32.7755 22.5432C32.7755 22.6296 32.6939 22.7593 32.5714 22.7593H19.4286C19.3469 22.7593 19.2245 22.6728 19.2245 22.5432V21.5494C19.2245 21.463 19.3061 21.3333 19.4286 21.3333H26.1633H32.5714C32.6531 21.3333 32.7755 21.4198 32.7755 21.5494V22.5432Z" fill="#005286" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="28" fill="white" transform="translate(18 12)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Cartel;