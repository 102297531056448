import React from 'react'

const Hoja = () => {
    return ( 
        <svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.23" d="M47.5 95C73.7335 95 95 73.7335 95 47.5C95 21.2665 73.7335 0 47.5 0C21.2665 0 0 21.2665 0 47.5C0 73.7335 21.2665 95 47.5 95Z" fill="#009FE3"/>
<path d="M1.82707 28.165C1.67483 28.6218 1.67483 29.2307 1.82707 29.6875C2.13156 30.2965 2.58829 30.7532 3.19727 31.0577L6.54662 32.1234C3.50175 35.1682 1.97932 38.5176 1.82707 42.0192C1.82707 43.9984 2.13156 45.8253 2.74053 47.5C4.87194 53.133 9.5915 56.9391 9.89598 57.2436C10.8094 58.0048 12.3319 57.8525 13.0931 56.9391C13.8543 56.0256 13.7021 54.5032 12.7886 53.742C12.7886 53.742 6.24214 48.4134 6.39438 42.3237C6.39438 40.3445 7.1556 38.5176 8.67803 36.6907V39.2788C8.67803 40.4968 9.5915 41.5625 10.9617 41.5625C12.1796 41.5625 13.2453 40.649 13.2453 39.2788L13.3976 31.3622C13.3976 31.2099 13.3976 30.9054 13.2453 30.7532V30.6009C13.2453 30.4487 13.0931 30.1442 12.9409 29.992C12.7886 29.8397 12.6364 29.6875 12.4841 29.5352C12.4841 29.5352 12.4841 29.5352 12.3319 29.383C12.1796 29.2307 12.0274 29.2307 11.7229 29.0785L4.41521 26.6426C3.50175 26.3381 2.13156 27.0993 1.82707 28.165Z" fill="#005286"/>
<path d="M94.2398 22.9888C94.0875 22.5321 93.6308 22.0753 93.1741 21.7709C92.5651 21.4664 91.8039 21.3141 91.1949 21.6186L87.8455 22.9888C88.3023 18.726 87.3888 15.2244 85.1052 12.3318C83.8872 10.8093 82.3648 9.59137 80.8423 8.67791C75.6661 5.78528 69.4241 5.78528 69.1196 5.78528C67.9016 5.78528 66.8359 6.85098 66.8359 8.06893C66.8359 9.28688 67.9016 10.3526 69.1196 10.3526C69.2718 10.3526 77.6452 10.3526 81.2991 15.0721C82.517 16.5946 83.126 18.5737 83.126 20.8574L81.4513 18.726C80.6901 17.8125 79.1677 17.508 78.2542 18.4215C77.3407 19.1827 77.0363 20.7051 77.9497 21.6186L82.9738 27.8606C83.126 28.0128 83.2782 28.1651 83.4305 28.3173C83.4305 28.3173 83.4305 28.3173 83.5827 28.3173C83.735 28.4696 83.8872 28.4696 84.1917 28.6218C84.3439 28.6218 84.6484 28.6218 84.8007 28.6218H84.9529C85.1052 28.6218 85.4097 28.6218 85.5619 28.4696L92.7173 25.7292C94.2398 25.5769 94.6965 24.2068 94.2398 22.9888Z" fill="#005286"/>
<path d="M68.8147 85.8654C69.2714 85.7132 69.7281 85.2564 70.0326 84.7997C70.3371 84.1907 70.4893 83.4295 70.1848 82.8205L68.8147 79.4712C73.0775 79.9279 76.5791 79.0144 79.4717 76.7308C80.9941 75.5128 82.2121 73.9904 83.1255 72.468C86.0182 67.2917 86.0182 61.0497 86.0182 60.7452C86.0182 59.5273 84.9525 58.4615 83.7345 58.4615C82.5166 58.4615 81.4509 59.5273 81.4509 60.7452C81.4509 60.8974 81.4509 69.2708 76.7313 72.9247C75.2089 74.1426 73.2297 74.7516 70.9461 74.7516L73.0775 73.0769C73.9909 72.3157 74.2954 70.7933 73.382 69.8798C72.6207 68.9664 71.0983 68.6619 70.1848 69.5753L63.9429 74.5994C63.7906 74.7516 63.6384 74.9039 63.4861 75.0561C63.4861 75.0561 63.4861 75.0561 63.4861 75.2083C63.3339 75.3606 63.3339 75.5128 63.1816 75.8173C63.1816 75.9696 63.1816 76.274 63.1816 76.4263V76.5785C63.1816 76.7308 63.1816 77.0353 63.3339 77.1875L66.0743 84.343C66.3788 85.8654 67.5967 86.3221 68.8147 85.8654Z" fill="#005286"/>
<path d="M26.7953 42.4759C22.3802 55.1121 28.3177 62.2675 31.6671 65.0079C34.8642 35.4727 53.8946 28.0127 54.8081 27.7082C56.0261 27.2515 57.244 27.8605 57.7007 29.0784C58.1575 30.2964 57.5485 31.5143 56.3305 31.9711C55.4171 32.2755 38.0613 39.2787 35.7777 67.5961C48.2616 73.6858 56.635 68.6618 56.9395 68.3573L57.0918 68.205C61.5068 65.9214 64.095 62.1153 64.3994 57.0912C64.7039 53.1329 63.6382 49.7836 63.6382 49.7836C59.3754 36.6906 63.3337 28.165 66.8354 23.7499C65.4652 23.7499 64.2472 23.5977 63.0293 23.5977C34.4075 23.7499 26.7953 42.1714 26.7953 42.4759Z" fill="#98C21D"/>
<path d="M73.5344 23.7499C73.0777 24.0544 62.8774 31.5143 68.3581 48.4134C70.1851 54.1986 70.794 66.5304 59.528 72.3156C58.7668 72.7724 54.504 75.2083 47.9575 75.2083C44.4559 75.2083 40.3453 74.5993 35.778 72.6201C35.778 77.7964 36.0825 83.4294 37.1482 89.8236C37.3004 91.0416 36.5392 92.2595 35.169 92.4118C35.0168 92.4118 34.8645 92.4118 34.8645 92.4118C33.7988 92.4118 32.7331 91.6506 32.5809 90.4326C31.3629 82.8204 31.0584 76.1217 31.3629 70.3365C30.4495 69.7275 15.6818 60.7451 22.6851 40.8012C23.1418 39.7355 33.1899 15.072 72.6209 19.6393C73.5344 19.7916 74.2956 20.4006 74.6001 21.314C74.6001 22.2275 74.2956 23.2932 73.5344 23.7499Z" fill="#98C21D"/>
<path d="M72.4686 19.6393C33.0375 15.072 22.9894 39.7355 22.5327 40.8012C15.5295 60.5929 30.2971 69.7275 31.2106 70.3365C30.9061 76.274 31.3628 82.9727 32.4285 90.4326C32.5808 91.4983 33.6465 92.4118 34.7122 92.4118C34.8644 92.4118 35.0167 92.4118 35.0167 92.4118C36.2346 92.2595 37.1481 91.0416 36.9958 89.8236C35.9301 83.4294 35.6257 77.7964 35.6257 72.6201C40.193 74.5993 44.3035 75.2083 47.8051 75.2083C54.3516 75.2083 58.6144 72.7724 59.3757 72.3156C70.6417 66.5304 70.0327 54.1986 68.2058 48.4134C62.725 31.6666 72.9253 24.0544 73.3821 23.7499C74.1433 23.141 74.4478 22.2275 74.2955 21.314C74.1433 20.4006 73.3821 19.7916 72.4686 19.6393ZM63.943 49.7836C63.943 49.7836 65.0087 53.1329 64.7042 57.0913C64.2475 62.2676 61.8116 65.9214 57.3965 68.205C57.3965 68.205 57.2442 68.2051 57.2442 68.3573C56.9398 68.6618 48.5664 73.6858 36.0824 67.5961C38.2138 39.2788 55.5696 32.2756 56.483 31.9711C57.701 31.5143 58.31 30.2964 57.8532 29.0785C57.3965 27.8605 56.1785 27.2515 54.9606 27.7083C53.8949 28.0127 35.0167 35.4727 31.6673 65.0079C28.318 62.2676 22.3805 55.1121 26.7955 42.4759C26.9478 42.3236 34.5599 23.7499 63.334 23.7499C64.5519 23.7499 65.7699 23.7499 67.1401 23.9022C63.6385 28.165 59.6801 36.6906 63.943 49.7836Z" fill="#005286"/>
</svg>

     );
}
 
export default Hoja;