import React from 'react';

const Users = () => {
    return (
        <svg width="87" height="86" viewBox="0 0 87 86" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="">
                <path opacity="0.23" d="M43.5008 86.0014C67.2894 86.0014 86.5738 66.7493 86.5738 43.0007C86.5738 19.2521 67.2894 0 43.5008 0C19.7122 0 0.427734 19.2521 0.427734 43.0007C0.427734 66.7493 19.7122 86.0014 43.5008 86.0014Z" fill="#98C21D" />
                <path d="M43.1556 37.1516C48.4585 37.1516 52.7737 32.8437 52.7737 27.5497C52.7737 22.2557 48.4585 17.9478 43.1556 17.9478C37.8513 17.9478 33.5361 22.2557 33.5361 27.5497C33.5361 32.8437 37.8513 37.1516 43.1556 37.1516Z" fill="#98C21D" />
                <path d="M43.1558 47.7852C34.1944 47.7852 26.7808 54.5376 25.7544 63.2141H60.5585C59.5308 54.5376 52.1172 47.7852 43.1558 47.7852Z" fill="#98C21D" />
                <path d="M15.0784 43.6535C17.3785 43.6535 19.2432 41.792 19.2432 39.4957C19.2432 37.1994 17.3785 35.3379 15.0784 35.3379C12.7782 35.3379 10.9136 37.1994 10.9136 39.4957C10.9136 41.792 12.7782 43.6535 15.0784 43.6535Z" fill="white" />
                <path d="M13.7731 55.2716C9.13378 55.2743 5.31386 58.4612 4.36475 62.7113H21.6613C21.7785 61.7307 21.9717 60.7749 22.2159 59.8398C18.8484 55.0939 13.9745 55.2592 13.7731 55.2716Z" fill="white" />
                <path d="M72.8942 44.5707C75.1897 44.5707 77.059 42.706 77.059 40.4129C77.059 38.1199 75.1911 36.2551 72.8942 36.2551C70.5973 36.2551 68.728 38.1199 68.728 40.4129C68.728 42.706 70.5973 44.5707 72.8942 44.5707Z" fill="white" />
                <path d="M73.3438 55.2744C73.0058 55.262 67.9361 55.0747 64.5673 60.136C64.479 60.2695 64.3755 60.3852 64.2651 60.4899C64.4307 61.2171 64.5617 61.9566 64.6514 62.71H82.6363C81.6886 58.4613 77.8811 55.2744 73.3438 55.2744Z" fill="white" />
                <path d="M43.156 41.2833C50.7406 41.2833 56.9127 35.1216 56.9127 27.5497C56.9127 19.9778 50.7406 13.8162 43.156 13.8162C35.57 13.8162 29.3979 19.9778 29.3979 27.5497C29.3979 35.1216 35.57 41.2833 43.156 41.2833ZM43.156 17.9464C48.4589 17.9464 52.7741 22.2543 52.7741 27.5483C52.7741 32.8423 48.4589 37.1503 43.156 37.1503C37.8517 37.1503 33.5365 32.8423 33.5365 27.5483C33.5365 22.2543 37.8517 17.9464 43.156 17.9464Z" fill="#005286" />
                <path d="M15.0783 47.7851C19.6556 47.7851 23.3817 44.0666 23.3817 39.4956C23.3817 34.9246 19.6569 31.2062 15.0783 31.2062C10.4996 31.2062 6.7749 34.9246 6.7749 39.4956C6.7749 44.0666 10.4996 47.7851 15.0783 47.7851ZM15.0783 35.3378C17.3738 35.3378 19.2431 37.2026 19.2431 39.4956C19.2431 41.7887 17.3752 43.6534 15.0783 43.6534C12.7814 43.6534 10.9135 41.7887 10.9135 39.4956C10.9135 37.2026 12.7828 35.3378 15.0783 35.3378Z" fill="#005286" />
                <path d="M72.8941 48.7023C77.4714 48.7023 81.1975 44.9838 81.1975 40.4128C81.1975 35.8419 77.4728 32.1234 72.8941 32.1234C68.3155 32.1234 64.5894 35.8419 64.5894 40.4128C64.5894 44.9838 68.3155 48.7023 72.8941 48.7023ZM72.8941 36.255C75.1897 36.255 77.0589 38.1198 77.0589 40.4128C77.0589 42.7059 75.191 44.5707 72.8941 44.5707C70.5972 44.5707 68.7279 42.7059 68.7279 40.4128C68.7279 38.1198 70.5972 36.255 72.8941 36.255Z" fill="#005286" />
                <path d="M73.4848 51.1469C73.482 51.1469 73.4792 51.1469 73.4765 51.1469C73.2047 51.1289 67.281 50.837 62.6499 55.912C59.1446 48.672 51.7365 43.6534 43.1558 43.6534C34.7697 43.6534 27.4968 48.4448 23.899 55.4217C19.3479 50.8439 13.8174 51.1289 13.656 51.1427C6.12511 51.1427 0 57.2589 0 64.7771C0 65.9188 0.927044 66.8429 2.06929 66.8429H22.2256C22.587 67.1514 23.0506 67.3456 23.5637 67.3456H62.7479C63.2611 67.3456 63.7246 67.1528 64.086 66.8429H84.9307C86.073 66.8429 87 65.9188 87 64.7771C87 57.2617 80.879 51.1469 73.4848 51.1469ZM21.6614 62.7113H4.36483C5.31395 58.4612 9.13386 55.2744 13.7732 55.2716C13.9733 55.2592 18.8485 55.0939 22.2159 59.8398C21.9718 60.7763 21.7786 61.7307 21.6614 62.7113ZM25.7544 63.214C26.7808 54.5375 34.1944 47.7851 43.1558 47.7851C52.1172 47.7851 59.5308 54.5375 60.5572 63.214H25.7544ZM64.6503 62.7113C64.5606 61.958 64.4295 61.2184 64.264 60.4912C64.3757 60.3866 64.4792 60.2695 64.5661 60.1373C67.9349 55.076 73.0047 55.262 73.3427 55.2757C77.8799 55.2757 81.6874 58.4626 82.6338 62.7127H64.6503V62.7113Z" fill="#005286" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="87" height="86" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default Users;