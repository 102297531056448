import React from 'react'

const ElipseBlue = () => {
    return (
        <svg className="elipse-work" width="1353" height="1467" viewBox="0 0 1353 1467" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="440" cy="913" r="913" fill="#005286" />
        </svg>

    );
}

export default ElipseBlue;