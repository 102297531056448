import React from 'react';

const Buy = () => {
    return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="26" cy="26" r="25.4348" stroke="#005286" stroke-width="1.13043" />
            <g clip-path="url(#clip0)">
                <path d="M22.0064 39.7584C22.6409 39.7584 23.1553 39.244 23.1553 38.6094C23.1553 37.9749 22.6409 37.4605 22.0064 37.4605C21.3718 37.4605 20.8574 37.9749 20.8574 38.6094C20.8574 39.244 21.3718 39.7584 22.0064 39.7584Z" fill="#009FE3" />
                <path d="M35.022 39.7584C35.6565 39.7584 36.1709 39.244 36.1709 38.6094C36.1709 37.9749 35.6565 37.4605 35.022 37.4605C34.3874 37.4605 33.873 37.9749 33.873 38.6094C33.873 39.244 34.3874 39.7584 35.022 39.7584Z" fill="#009FE3" />
                <path d="M20.7377 29.612C20.9894 29.5928 21.2421 29.5928 21.4938 29.612H35.9623L39.5135 17.9685H18.2559L20.7377 29.612Z" fill="#8DB72E" />
                <path d="M40.5187 16.4763H17.9579L17.1074 12.5919C17.072 12.4243 16.98 12.274 16.8469 12.1662C16.7138 12.0584 16.5476 11.9997 16.3763 12H10.7461C10.5482 12 10.3584 12.0786 10.2185 12.2185C10.0786 12.3584 10 12.5482 10 12.7461C10 12.9439 10.0786 13.1337 10.2185 13.2736C10.3584 13.4135 10.5482 13.4921 10.7461 13.4921H15.7745L19.2859 29.9551C18.7734 30.1663 18.3385 30.5303 18.0404 30.9975C17.7422 31.4648 17.5953 32.0126 17.6197 32.5663C17.6393 32.948 17.7348 33.322 17.9006 33.6664C18.0664 34.0108 18.2992 34.3187 18.5854 34.572C18.8716 34.8253 19.2055 35.019 19.5674 35.1418C19.9294 35.2646 20.3122 35.3139 20.6935 35.2869H36.9277C37.1256 35.2869 37.3153 35.2083 37.4553 35.0684C37.5952 34.9285 37.6738 34.7388 37.6738 34.5409C37.6738 34.343 37.5952 34.1533 37.4553 34.0133C37.3153 33.8734 37.1256 33.7948 36.9277 33.7948H20.7084C19.2859 33.7948 19.1268 32.8001 19.1119 32.5514C19.0966 32.3679 19.1227 32.1833 19.1881 32.0112C19.2535 31.839 19.3566 31.6837 19.4899 31.5567C20.0449 31.1827 20.7149 31.0187 21.3799 31.0941H36.5149C36.675 31.0941 36.8309 31.0423 36.9591 30.9464C37.0873 30.8504 37.181 30.7156 37.2261 30.5619L41.235 17.4711C41.2764 17.3575 41.2894 17.2356 41.2729 17.1158C41.2563 16.9961 41.2108 16.8822 41.1401 16.7841C41.0695 16.6861 40.976 16.6067 40.8677 16.5531C40.7594 16.4994 40.6396 16.4731 40.5187 16.4763ZM35.9628 29.6169H21.4943C21.2426 29.5977 20.9899 29.5977 20.7383 29.6169L18.2564 17.9685H39.514L35.9628 29.6169Z" fill="#005286" />
                <path d="M22.0063 35.9684C21.4839 35.9684 20.9733 36.1233 20.539 36.4135C20.1047 36.7037 19.7662 37.1162 19.5663 37.5987C19.3664 38.0813 19.3141 38.6124 19.416 39.1247C19.5179 39.637 19.7694 40.1076 20.1388 40.4769C20.5081 40.8463 20.9787 41.0978 21.491 41.1997C22.0034 41.3016 22.5344 41.2493 23.017 41.0494C23.4996 40.8495 23.912 40.511 24.2022 40.0767C24.4924 39.6424 24.6473 39.1318 24.6473 38.6094C24.6473 37.909 24.3691 37.2372 23.8738 36.7419C23.3785 36.2466 22.7067 35.9684 22.0063 35.9684ZM22.0063 39.7584C21.779 39.7584 21.5569 39.691 21.368 39.5647C21.179 39.4385 21.0318 39.259 20.9448 39.0491C20.8578 38.8392 20.8351 38.6082 20.8794 38.3853C20.9238 38.1624 21.0332 37.9577 21.1939 37.797C21.3545 37.6363 21.5593 37.5269 21.7821 37.4826C22.005 37.4382 22.236 37.461 22.446 37.548C22.6559 37.6349 22.8353 37.7822 22.9616 37.9711C23.0878 38.1601 23.1552 38.3822 23.1552 38.6094C23.1552 38.9141 23.0342 39.2064 22.8187 39.4218C22.6032 39.6373 22.311 39.7584 22.0063 39.7584Z" fill="#005286" />
                <path d="M35.0219 35.9684C34.4996 35.9684 33.9889 36.1233 33.5546 36.4135C33.1203 36.7037 32.7818 37.1162 32.5819 37.5987C32.382 38.0813 32.3297 38.6124 32.4316 39.1247C32.5335 39.637 32.785 40.1076 33.1544 40.4769C33.5238 40.8463 33.9943 41.0978 34.5067 41.1997C35.019 41.3016 35.55 41.2493 36.0326 41.0494C36.5152 40.8495 36.9277 40.511 37.2179 40.0767C37.5081 39.6424 37.6629 39.1318 37.6629 38.6094C37.6616 37.9094 37.383 37.2384 36.888 36.7434C36.3929 36.2484 35.7219 35.9697 35.0219 35.9684ZM35.0219 39.7584C34.7947 39.7584 34.5725 39.691 34.3836 39.5647C34.1947 39.4385 34.0474 39.259 33.9604 39.0491C33.8735 38.8392 33.8507 38.6082 33.8951 38.3853C33.9394 38.1624 34.0488 37.9577 34.2095 37.797C34.3702 37.6363 34.5749 37.5269 34.7978 37.4826C35.0206 37.4382 35.2516 37.461 35.4616 37.548C35.6715 37.6349 35.851 37.7822 35.9772 37.9711C36.1034 38.1601 36.1708 38.3822 36.1708 38.6094C36.1708 38.9141 36.0498 39.2064 35.8343 39.4218C35.6189 39.6373 35.3266 39.7584 35.0219 39.7584Z" fill="#005286" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="31.2648" height="29.2504" fill="white" transform="translate(10 12)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Buy;