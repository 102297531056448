export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SEND_CONTACT = 'SEND_CONTACT'
export const SEND_CONTACT_ERROR = 'SEND_CONTACT_ERROR'
export const SEND_NEW = 'SEND_NEW'
export const SEND_NEW_ERROR = 'SEND_NEW_ERROR'
export const SEND_BANNER = 'SEND_BANNER'
export const SEND_BANNER_ERROR = 'SEND_BANNER_ERROR'
export const SEND_NEWSPAPER = 'SEND_NEWSPAPER'
export const SEND_NEWSPAPER_ERROR = 'SEND_NEWSPAPER_ERROR'
export const SEND_POSTULATION = 'SEND_POSTULATION'
export const SEND_POSTULATION_ERROR = 'SEND_POSTULATION_ERROR'
export const SEND_VACANT = 'SEND_VACANT'
export const SEND_VACANT_ERROR = 'SEND_VACANT_ERROR'
export const SEND_ACTIVITY = 'SEND_ACTIVITY'
export const SEND_ACTIVITY_ERROR = 'SEND_ACTIVITY_ERROR'
export const SEND_COMMUNITY = 'SEND_COMMUNITY'
export const SEND_COMMUNITY_ERROR = 'SEND_COMMUNITY_ERROR'
export const SEND_PRODUCT = 'SEND_PRODUCT'
export const SEND_PRODUCT_ERROR = 'SEND_PRODUCT_ERROR'
export const GET_NEW_HOME = 'GET_NEW_HOME'
export const GET_NEW_HOME_ERROR = 'GET_NEW_HOME_ERROR'
export const GET_NEW = 'GET_NEW'
export const GET_NEW_ERROR = 'GET_NEW_ERROR'
export const GET_BANNER = 'GET_BANNER'
export const GET_BANNER_ERROR = 'GET_BANNER_ERROR'
export const GET_LINES_PRODUCTS = 'GET_LINES_PRODUCTS'
export const GET_LINES_PRODUCTS_ERROR = 'GET_LINES_PRODUCTS_ERROR'
export const GET_TYPES_PRODUCTS = 'GET_TYPES_PRODUCT'
export const GET_TYPES_PRODUCTS_ERROR = 'GET_TYPES_PRODUCTS_ERROR'
export const GET_TYPES_BUSINESS = 'GET_TYPES_BUSINESS'
export const GET_TYPES_BUSINESS_ERROR = 'GET_TYPES_BUSINESS_ERROR'
export const GET_SUBTYPES_PRODUCTS = 'GET_SUBTYPES_PRODUCTS'
export const GET_SUBTYPES_PRODUCTS_ERROR = 'GET_SUBTYPES_PRODUCTS_ERROR'
export const GET_ADD_SUBTYPES_PRODUCTS = 'GET_ADD_SUBTYPES_PRODUCTS'
export const GET_ADD_SUBTYPES_PRODUCTS_ERROR = 'GET_ADD_SUBTYPES_PRODUCTS_ERROR'
export const GET_MATERIALS = 'GET_MATERIALS'
export const GET_MATERIALS_ERROR = 'GET_MATERIALS_ERROR'
export const GET_PRODUCTS_BY_FILTER = 'GET_PRODUCTS_BY_FILTER'
export const GET_PRODUCTS_BY_FILTER_ERROR = 'GET_PRODUCTS_BY_FILTER_ERROR'
export const GET_COUNT_PRODUCT_BY_FILTER = 'GET_COUNT_PRODUCT_BY_FILTER'
export const GET_COUNT_PRODUCT_BY_FILTER_ERROR = 'GET_COUNT_PRODUCT_BY_FILTER_ERROR'
export const GET_PRODUCTS_BY_TYPE = 'GET_PRODUCTS_BY_TYPE'
export const GET_PRODUCTS_BY_TYPE_ERROR = 'GET_PRODUCTS_BY_TYPE_ERROR'
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS'
export const GET_ALL_PRODUCTS_ERROR = 'GET_ALL_PRODUCTS_ERROR'
export const GET_ACTIVITIES = 'GET_ACTIVITIES'
export const GET_ACTIVITIES_ERROR = 'GET_ACTIVITIES_ERROR'
export const GET_COMMUNITIES = 'GET_COMMUNITIES'
export const GET_COMMUNITIES_ERROR = 'GET_COMMUNITIES_ERROR'
export const DELETE_BANNER = 'DELETE_BANNER'
export const DELETE_BANNER_ERROR = 'DELETE_BANNER_ERROR'
export const DELETE_VACANT = 'DELETE_VACANT'
export const DELETE_VACANT_ERROR = 'DELETE_VACANT_ERROR'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR'
export const DELETE_NEW = 'DELETE_NEW'
export const DELETE_NEW_ERROR = 'DELETE_NEW_ERROR'
export const DELETE_NEWPAPER = 'DELETE_NEWPAPER'
export const DELETE_NEWPAPER_ERROR = 'DELETE_NEWPAPER_ERROR'
export const DELETE_COMMUNITY = 'DELETE_COMMUNITY'
export const DELETE_COMMUNITY_ERROR = 'DELETE_COMMUNITY_ERROR'
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY'
export const DELETE_ACTIVITY_ERROR = 'DELETE_ACTIVITY_ERROR'
export const GET_NEWSPAPER = 'GET_NEWSPAPER'
export const GET_NEWSPAPER_ERROR = 'GET_NEWSPAPER'
export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR'
export const DELETE_IMAGE_PRODUCT = 'DELETE_IMAGE_PRODUCT'
export const DELETE_IMAGE_PRODUCT_ERROR = 'DELETE_IMAGE_PRODUCT_ERROR'
export const GET_NEW_ID = 'GET_NEW_ID'
export const GET_NEW_ID_ERROR = 'GET_NEW_ID_ERROR'
export const GET_VACANT = 'GET_VACANT'
export const GET_VACANT_ERROR = 'GET_VACANT_ERROR'
export const UPDATE_VACANT = 'UPDATE_VACANT'
export const UPDATE_VACANT_ERROR = 'UPDATE_VACANT_ERROR'
export const UPDATE_NEW = 'UPDATE_NEW'
export const UPDATE_NEW_ERROR = 'UPDATE_NEW_ERROR'
export const UPDATE_NEWPAPER = 'UPDATE_NEWPAPER_'
export const UPDATE_NEWPAPER_ERROR = 'UPDATE_NEWPAPER_ERROR'
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY'
export const UPDATE_ACTIVITY_ERROR = 'UPDATE_ACTIVITY_ERROR'
export const UPDATE_COMMUNITY = 'UPDATE_COMMUNITY'
export const UPDATE_COMMUNITY_ERROR = 'UPDATE_COMMUNITY_ERROR'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR'

export const ADD_CART = 'ADD_CART'
export const ADD_CART_ERROR = 'ADD_CART_ERROR'
export const DELETE_CART = 'DELETE_CART'
export const SEND_CART = 'SEND_CART'
export const SEND_CART_ERROR = 'SEND_CART_ERROR'

export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS'
export const SIGNIN_ERROR = 'SIGNIN_ERROR'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'

export const SEND_NEW_FILE = 'SEND_NEW_FILE'
export const SEND_NEW_FILE_ERROR = 'SEND_NEW_FILE_ERROR'

export const GET_COUNTRY = 'GET_COUNTRY'
export const GET_COUNTRY_ERROR = 'GET_COUNTRY_ERROR'
export const GET_CITY = 'GET_CITY'
export const GET_CITY_ERROR = 'GET_CITY_ERROR'
export const GET_DEPARTMENT = 'GET_DEPARTMENT'
export const GET_DEPARTMENT_ERROR = 'GET_DEPARTMENT_ERROR'
export const GET_PROVINCE = 'GET_PROVINCE'
export const GET_PROVINCE_ERROR = 'GET_PROVINCE_ERROR'
export const GET_DISTRICT = 'GET_DISTRICT'
export const GET_DISTRICT_ERROR = 'GET_DISTRICT_ERROR'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'
export const GET_KEYWORDS = 'GET_KEYWORDS'
export const GET_KEYWORDS_ERROR = 'GET_KEYWORDS_ERROR'