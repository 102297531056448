import React from 'react'

const PlantSbl = () => {
    return (
    <svg width="96" height="107" viewBox="0 0 96 107" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="">
            <path opacity="0.23" d="M48.8306 107C74.8811 107 95.9991 85.8592 95.9991 59.7808C95.9991 33.7023 74.8811 12.5615 48.8306 12.5615C22.7802 12.5615 1.66211 33.7023 1.66211 59.7808C1.66211 85.8592 22.7802 107 48.8306 107Z" fill="#009FE3" />
            <path d="M41.7251 73.553H34.166V78.0933H41.7251V73.553Z" fill="#8DB72E" />
            <path d="M21.9204 73.553H14.3613V78.0933H21.9204V73.553Z" fill="#8DB72E" />
            <path d="M62.1348 71.4342H55.0293V90.2008H62.1348V71.4342Z" fill="#FBBA00" />
            <path d="M82.393 11.6534H77.5552L74.834 51.3055H84.9631L82.393 11.6534Z" fill="#009FE3" />
            <path d="M63.9493 53.5757V46.3112L51.5525 57.6619C50.9478 58.2673 49.8895 58.4187 49.1336 58.116C48.3777 57.8133 47.773 56.9052 47.773 55.9972V48.43L37.4926 58.7213C36.8879 59.3267 35.8296 59.6294 35.0737 59.1754C34.1667 58.8727 33.7131 57.9646 33.7131 57.0566V47.6732L21.1651 57.6619C20.4092 58.2673 19.5021 58.2673 18.7462 57.9646C17.9903 57.6619 17.3855 56.7539 17.3855 55.8458V46.9165L4.53516 54.9377V90.2008H50.4942V69.1641C50.4942 67.9533 51.5525 66.8939 52.7619 66.8939H64.4029C65.6123 66.8939 66.6706 67.9533 66.6706 69.1641V90.2008H89.6501V55.8458H66.217C65.0076 55.8458 63.9493 54.9377 63.9493 53.5757ZM26.4564 80.5148C26.4564 81.7256 25.3981 82.785 24.1887 82.785H12.0942C10.8848 82.785 9.82649 81.7256 9.82649 80.5148V71.4342C9.82649 70.2235 10.8848 69.1641 12.0942 69.1641H24.1887C25.3981 69.1641 26.4564 70.2235 26.4564 71.4342V80.5148ZM46.2611 80.5148C46.2611 81.7256 45.2029 82.785 43.9934 82.785H31.8989C30.6895 82.785 29.6312 81.7256 29.6312 80.5148V71.4342C29.6312 70.2235 30.6895 69.1641 31.8989 69.1641H43.9934C45.2029 69.1641 46.2611 70.2235 46.2611 71.4342V80.5148Z" fill="white" />
            <path d="M92.0693 51.3055H89.4992L86.7779 9.23197C86.6268 8.02122 85.7197 7.11316 84.5102 7.11316H75.4394C74.2299 7.11316 73.3228 8.02122 73.1716 9.23197L70.2992 51.3055H68.485V41.1655C68.485 40.2574 68.0315 39.5007 67.1244 39.0467C66.3685 38.744 65.3102 38.8953 64.7055 39.5007L52.3087 50.8515V42.9816C52.3087 42.0736 51.7039 41.1655 50.948 40.8628C50.0409 40.5601 49.1339 40.7115 48.5291 41.3168L38.2488 51.6082V42.9816C38.2488 42.0736 37.7953 41.3168 36.8882 40.8628C36.1323 40.5601 35.2252 40.5601 34.4693 41.1655L22.0724 51.1542V42.8303C22.0724 42.0736 21.6189 41.3168 20.863 40.8628C20.1071 40.4088 19.2 40.4088 18.5953 40.8628L1.05827 51.6082C0.453543 52.0622 0 52.819 0 53.5757V92.471C0 93.6818 1.05827 94.7412 2.26772 94.7412H92.0693C93.2787 94.7412 94.337 93.6818 94.337 92.471V53.5757C94.337 52.3649 93.4299 51.3055 92.0693 51.3055ZM77.5559 11.6535H82.3937L85.115 51.3055H74.9858L77.5559 11.6535ZM62.2866 90.2009H55.1811V71.4342H62.2866V90.2009ZM89.8016 90.2009H66.822V69.1641C66.822 67.9533 65.7638 66.8939 64.5543 66.8939H52.9134C51.7039 66.8939 50.6457 67.9533 50.6457 69.1641V90.2009H4.53543V54.9378L17.537 47.0679V55.9972C17.537 56.9052 17.9905 57.662 18.8976 58.116C19.6535 58.4187 20.5606 58.4187 21.3165 57.8133L33.7134 47.8246V57.2079C33.7134 58.116 34.3181 59.0241 35.074 59.3267C35.9811 59.6294 36.8882 59.4781 37.4929 58.8727L47.7732 48.5813V56.1485C47.7732 57.0566 48.2268 57.8133 49.1339 58.2673C49.8898 58.57 50.948 58.4187 51.5527 57.8133L63.9496 46.4625V53.727C63.9496 54.9378 65.0079 55.9972 66.2173 55.9972H89.8016V90.2009Z" fill="#005286" />
            <path d="M52.1577 4.69164C52.1577 4.69164 53.216 4.23761 54.1231 4.99432C54.2743 5.14567 54.2743 5.14567 54.4254 5.14567C54.7278 5.29701 56.542 6.50776 59.1121 6.50776C59.868 6.50776 60.6239 6.35642 61.3798 6.20507C61.531 6.20507 61.531 6.20507 61.6821 6.05373C63.1939 5.44836 65.0081 5.29701 65.3105 5.44836C66.0664 6.50776 67.427 6.6591 68.4853 5.90239C69.5436 5.14567 69.6947 3.78357 68.9388 2.72417C66.5199 -0.454049 61.531 1.21073 60.1703 1.66476C58.5073 1.96745 57.2979 1.51342 56.9955 1.21073C54.5766 -0.90808 51.553 -0.302705 50.1924 0.454013C49.1341 1.05939 48.6806 2.42148 49.1341 3.48089C49.7388 4.84298 51.0995 5.29701 52.1577 4.69164Z" fill="#005286" />
            <path d="M24.1884 69.0127H12.0939C10.8844 69.0127 9.82617 70.0721 9.82617 71.2829V80.3635C9.82617 81.5742 10.8844 82.6336 12.0939 82.6336H24.1884C25.3978 82.6336 26.4561 81.5742 26.4561 80.3635V71.2829C26.4561 70.0721 25.3978 69.0127 24.1884 69.0127ZM21.9207 78.2447H14.3616V73.7044H21.9207V78.2447Z" fill="#005286" />
            <path d="M43.9931 69.0127H31.8986C30.6891 69.0127 29.6309 70.0721 29.6309 71.2829V80.3635C29.6309 81.5742 30.6891 82.6336 31.8986 82.6336H43.9931C45.2025 82.6336 46.2608 81.5742 46.2608 80.3635V71.2829C46.2608 70.0721 45.2025 69.0127 43.9931 69.0127ZM41.7253 78.2447H34.1663V73.7044H41.7253V78.2447Z" fill="#005286" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="96" height="107" fill="white" />
            </clipPath>
        </defs>
    </svg>
    );
}

export default PlantSbl;