import React from 'react';

const Hamburguer = () => {
    return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="26" cy="26" r="25.4348" stroke="#005286" stroke-width="1.13043" />
            <path d="M29.3655 23.4466V24.1493H30.3537L30.1185 11.8741C28.2361 13.4671 27.4361 18.6676 27.2949 22.3221L28.8479 22.6969C29.1773 22.7906 29.3655 23.1186 29.3655 23.4466Z" fill="white" />
            <path d="M23.6707 39.3762L23.2472 21.6661H22.4942L21.9766 39.4231C21.9766 39.7042 22.0236 40.5475 22.8707 40.5944C22.9177 40.5944 22.9177 40.5944 22.9648 40.5944C22.9648 40.5944 23.2942 40.6412 23.4824 40.407C23.6707 40.2196 23.7177 39.8916 23.6707 39.4231C23.6707 39.4231 23.6707 39.4231 23.6707 39.3762Z" fill="#8DB72E" />
            <path d="M21.4121 18.4802L22.3533 20.2606H23.3886L24.3298 18.4802V17.1215H21.4121V18.4802Z" fill="white" />
            <path d="M29.3182 25.5549L28.8477 40.2196C28.8947 40.5475 29.13 40.5944 29.2241 40.5944H30.2124C30.3065 40.5944 30.5418 40.5944 30.5888 40.2196L30.3535 25.5549H29.3182Z" fill="#FBBA00" />
            <path d="M25.0353 10.0469C24.6588 10.0469 24.3294 10.3748 24.3294 10.7496V15.716H23.5765V10.7028C23.5765 10.328 23.2471 10 22.8706 10C22.4941 10 22.1647 10.328 22.1647 10.7028V15.716H21.4118V10.7496C21.4118 10.3748 21.0824 10.0469 20.7059 10.0469C20.3294 10.0469 20 10.3748 20 10.7496V18.6676C20 18.7613 20.0471 18.9019 20.0941 18.9956L21.0824 20.9634L20.5647 39.3763C20.5647 39.5168 20.5647 40.5944 21.2706 41.3441C21.6471 41.7657 22.2118 42 22.8235 42C22.8706 42 22.9176 42 23.0118 42C23.3412 42 23.9529 41.9063 24.4706 41.4378C24.9882 40.9693 25.1765 40.2665 25.0824 39.3294L24.6118 21.0102C24.6118 21.0102 24.6118 21.0102 24.6118 20.9634L25.6471 18.9956C25.6941 18.9019 25.7412 18.7613 25.7412 18.6676V10.7496C25.7412 10.3748 25.4118 10.0469 25.0353 10.0469ZM23.4824 40.4539C23.2941 40.6413 22.9647 40.6413 22.9647 40.6413C22.9176 40.6413 22.9176 40.6413 22.8706 40.6413C21.9765 40.6413 21.9294 39.7511 21.9765 39.47L22.4941 21.713H23.2471L23.6706 39.4231V39.47C23.7176 39.9385 23.6235 40.2665 23.4824 40.4539ZM24.3294 18.4802L23.3882 20.2606H22.3529L21.4118 18.4802V17.1215H24.3294V18.4802Z" fill="#005286" />
            <path d="M31.483 10.7496C31.483 10.5154 31.3889 10.328 31.2006 10.1874C31.0124 10.0468 30.8242 10.0468 30.6359 10.0937C26.1653 11.0776 25.883 21.6662 25.8359 22.8843C25.8359 23.2123 26.0712 23.4934 26.3536 23.5871L27.9065 23.9619L27.3889 40.2196V40.2665C27.4359 41.4846 28.4712 42 29.1301 42H30.1653C31.3889 42 31.9065 40.9692 31.9536 40.3133V40.2665L31.483 10.7496ZM27.2948 22.3221C27.4359 18.6676 28.2359 13.467 30.1183 11.8741L30.3536 24.1493H29.3653V23.4466C29.3653 23.1186 29.1301 22.8375 28.8477 22.7438L27.2948 22.3221ZM30.2595 40.5944H29.2712C29.1771 40.5944 28.9418 40.5476 28.8948 40.2196L29.3653 25.5549H30.4006L30.683 40.2196C30.5418 40.5944 30.3065 40.5944 30.2595 40.5944Z" fill="#005286" />
        </svg>

    );
}

export default Hamburguer;