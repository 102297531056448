import React from 'react';

const Arrow = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9995 6.70499C14.8127 6.51774 14.559 6.41251 14.2945 6.41251C14.03 6.41251 13.7764 6.51774 13.5895 6.70499L8.99953 11.295C8.60953 11.685 8.60953 12.315 8.99953 12.705L13.5895 17.295C13.9795 17.685 14.6095 17.685 14.9995 17.295C15.3895 16.905 15.3895 16.275 14.9995 15.885L11.1195 11.995L14.9995 8.11499C15.3895 7.72499 15.3795 7.08499 14.9995 6.70499Z" fill="white" />
        </svg>
    );
}

export default Arrow;