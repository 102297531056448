import React from 'react'

const CheckSend = () => {
    return (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 0C8.52298 0 0 8.52298 0 19C0 29.477 8.52298 38 19 38C29.477 38 38 29.477 38 19C38 8.52298 29.477 0 19 0Z" fill="#8DB72E" />
            <path d="M28.629 14.9736L18.3372 25.2651C18.0284 25.5739 17.6231 25.7293 17.2178 25.7293C16.8125 25.7293 16.4072 25.5739 16.0985 25.2651L10.9527 20.1194C10.3335 19.5004 10.3335 18.4996 10.9527 17.8806C11.5717 17.2614 12.5722 17.2614 13.1915 17.8806L17.2178 21.907L26.3902 12.7349C27.0092 12.1156 28.0097 12.1156 28.629 12.7349C29.2479 13.3539 29.2479 14.3544 28.629 14.9736Z" fill="white" />
        </svg>
    );
}

export default CheckSend;