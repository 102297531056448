import React from 'react'

const ContactCall = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="25" cy="25" r="25" fill="#98C21D" />
            <path d="M32.2232 28.2682L29.6832 27.9782C29.0732 27.9082 28.4732 28.1182 28.0432 28.5482L26.2032 30.3882C23.3732 28.9482 21.0532 26.6382 19.6132 23.7982L21.4632 21.9482C21.8932 21.5182 22.1032 20.9182 22.0332 20.3082L21.7432 17.7882C21.6232 16.7782 20.7732 16.0182 19.7532 16.0182H18.0232C16.8932 16.0182 15.9532 16.9582 16.0232 18.0882C16.5532 26.6282 23.3832 33.4482 31.9132 33.9782C33.0432 34.0482 33.9832 33.1082 33.9832 31.9782V30.2482C33.9932 29.2382 33.2332 28.3882 32.2232 28.2682Z" fill="white" />
        </svg>
    );
}

export default ContactCall;